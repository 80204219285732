.tooltip {
    position: relative;
    display: inline-block; 
}

.tooltip h1 {
    color: black;
}

.tooltip Link {
    text-decoration: none;
}

.tooltip .tooltipcontents {
    visibility: hidden;
    width: 100px;
    color: white;
    background-color: #333;
    text-align: center;
    position: absolute;
    border-radius: 5px;
    top: 33px;
    right: 105%;
}

.tooltip:hover .tooltipcontents {
    visibility: visible;
}