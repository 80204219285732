.pfp {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.minipfp {
    width: 25px;
    height: 25px;
}

.commentBox {
    width: 100%;
    height: 100px;
}

.commentWrapper {
    background-color: #d1d1d1;;
    border-width: 0px;
    border-radius: 10px;
    padding: 8px;
    margin-bottom: 15px;
}

.commentDeleted {
    background-color: #d2abab;
}

.commentImg {
    width: 100%;
    padding-top: 20px;
}

.commentBody {
    word-break: break-word;
    line-height: 200%;
    white-space: pre-line;
}

.image {
    max-width: 200px;
    max-height: 200px;
}

@media only screen and (min-width: 700px) {  
    .image {
        max-width: 400px;
        max-height: 400px;
    }
    .commentImg {
        padding-top: 0px;
        float: left;
        margin-left: 50px;
    }
    .commentBody {
        margin-left: 50px;
        margin-right: 30px;
        float: left;
    }
    .commentBox {
        height: 100px;
        width: 600px;
    }
}

.commentBodyWrapper {
    display: inline-block;
}

.topRepliesBox {
    margin-left: 50px;
    border-left: solid 2px black;
    padding-left: 5px;
}

