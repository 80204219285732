.threadCell {
    /* border-style: solid; */
    /* border-width: 0px;
    border-top-width: 2px;
    border-collapse: collapse; */
    padding: 10px;
    text-decoration: none;
}

.threadRowDiv {
    border-style: solid;
    border-width: 2px;
    border-radius: 6px;
    background-color: #d1d1d1;
    border-color: rgb(16, 16, 119);
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.ThreadRowLink {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: black;
}

.threadCell a {
    color: black;
    text-decoration: none;
}

.threadTable {
    width: 100%;
    border-spacing: 0;
}

/*
.threadRow:nth-child(even) {
    background-color: #cad5db;
}
*/

.threadRow {
    width: 100%;
}

.threadPinCell {
    width: 5%;
}

.threadTitleCell {
    width: 15%;
}

.threadDescCell {
    width: 31%;
}

.threadDeletedCell {
    width: 4%;
}

.threadUserCell {
    width: 19%;
}

.threadActCell {
    width: 12.5%;
}

.threadCreateCell {
    width: 12.5%;
}
